jQuery(function ($) {



$(function() {
	
  $('.site-header .btn-nav').click(function() {
    //$('.site-header .header-menu').slideToggle(400);
    $('.site-header .header-menu').fadeToggle(200);
    $(this).toggleClass('active');
  });
	
  $(window).scroll(function (){
    $(".effect").each(function(){
      var imgPos = $(this).offset().top;
      var scroll = $(window).scrollTop();
      var windowHeight = $(window).height();
      if (scroll > imgPos - windowHeight + windowHeight/5){
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }
    });
  });	

	$('.item_top').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				top : "0px"
			}, 300);
		});
	});

	$('.item_bottom').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				bottom : "0"
			}, 300);
		});
	});

	$('.item_left').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				left : "0px"
			}, 300);
		});
	});
   
   
	
	$('.item_right').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				right : "0px"
			}, 300);
		});
	});
	
	$('.item_fade_in').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				right : "0px"
			}, 300);
		});
	});
   
	$('.item_lyric').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				left : "4vw"
			}, 300);
		});
	});

	$('.item_c_left').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				left : "20px"
			}, 1000);
		});
	});
	
	$('.item_c_right').each(function() {
		$(this).appear(function() {
			$(this).delay(100).animate({
				opacity : 1,
				right : "20px"
			}, 1000);
		});
	});
	

});

// メニューボタン,ヘッダー縮小
$(document).ready(
	function() {
    $('.btn-1').click(function () {
        $('.open-1').toggleClass('active');
        $(this).toggleClass('on');
        $(this).toggleClass('move-1');
    });


});

//pageTop
$(function() {
	var topBtn = $('#pageTop,.fixed_foot_menu');
	topBtn.hide();
	$(window).scroll(function () {
		if ($(this).scrollTop() > 10) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});
    topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 360);
		return false;
    });
});


});

